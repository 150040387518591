import AMap from "vue-amap";
//import "echarts/map/js/china.js";
export default {
	name: "smoStatistics",
	data() {
		return {
			count1: 0,
			count2: 0,
			count3: 0,
			count4: 0,
			markers: [],
			zoom: 4,
			center: [121.59996, 31.197646],
			token: "",
			userId: "",
			windows: [],
			window: ""
		};
	},

	//		created(){
	//			this.convertData();
	//		},
	mounted() {
		this.drawLine();
		let echar2 = this.$echarts.init(document.getElementById("echar-2"));
		var _this = this;
		echar2.on("click", function (e) {
			_this.$store.commit("newResult", e.name);
			_this.$router.push("/projectList");
		});
	},
	created() {
		var _this = this;
		this.token = this.getCookie("token");
		this.userId = this.getCookie("userId");
		_this.getProjectsts1();
		_this.getProjectsts2();
		_this.getProjectsts3();
		_this.getProjectsts4();
		_this.getMapData();
	},
	methods: {
		getProjectsts1() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/smoInCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.count1 = response.data.data;
					}
				});
		},
		getProjectsts2() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/smoPCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.count2 = response.data.data;
					}
				});
		},
		getProjectsts3() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/smoBidCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.count3 = response.data.data;
					}
				});
		},
		getProjectsts4() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/smoCrcCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.count4 = response.data.data;
					}
				});
		},
		convertData(data) {
			var _this = this;
			var res = [];
			for (var i = 0; i < data.length; i++) {
				var geoCoord = _this.geoCoordMap[data[i].name];
				if (geoCoord) {
					res.push({
						name: data[i].name,
						value: geoCoord.concat(data[i].value)
					});
				}
			}
			return res;
		},
		drawLine() {
			//console.log(res)
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// let echar1 = this.$echarts.init(document.getElementById("echar-1"))
			let echar2 = this.$echarts.init(document.getElementById("echar-2"));
			// 绘制图表
			_this.$http
				.post(
					"/API/PLAT/smoCountB",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						echar2.setOption({
							tooltip: {
								trigger: "axis",
								axisPointer: {
									// 坐标轴指示器，坐标轴触发有效
									type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
								}
							},
							legend: {
								data: ["CRC人数"]
							},
							grid: {
								left: "3%",
								right: "4%",
								bottom: "3%",
								containLabel: true
							},
							xAxis: {
								type: "value",
								min: 0,
								max: 20,
								interval: 2,
								axisLine: {
									lineStyle: {
										color: "##CBD1DA"
									}
								}
							},
							yAxis: {
								type: "category",
								data: response.data.data.y,
								//              data: [1,2,3,4],
								axisLine: {
									lineStyle: {
										color: "##CBD1DA"
									}
								},
								axisLabel: {
									interval: 0,
									formatter: function (params) {
										var newParamsName = "";
										var paramsNameNumber = params.length;
										var provideNumber = 8;
										var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
										if (paramsNameNumber > provideNumber) {
											for (var p = 0; p < rowNumber; p++) {
												var tempStr = "";
												var start = p * provideNumber;
												var end = start + provideNumber;
												if (p == rowNumber - 1) {
													tempStr = params.substring(start, paramsNameNumber);
												} else {
													tempStr = params.substring(start, end) + "\n";
												}
												newParamsName += tempStr;
											}
										} else {
											newParamsName = params;
										}
										return newParamsName;
									}
								}
							},

							series: [
								{
									name: "CRC人数",
									type: "bar",
									barWidth: "50%",
									color: "#539CF2",
									label: {
										normal: {
											show: true,
											position: "insideRight"
										}
									},
									data: response.data.data.x
									//                data: [200,300,400,500]
								}
							]
						});
					}
				});
		},
		toOrganizationList() {
			this.$router.push("/organizationList");
		},
		toProjectList() {
			this.$router.push("/projectList");
			this.$store.commit("newResult", "");
		},
		getMapData: function () {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// let echar1 = this.$echarts.init(document.getElementById("echar-1"));
			// 绘制图表
			_this.$http
				.post(
					"/API/PLAT/institutionCoordinate",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					console.log(response);
					if (response.data.code == 200) {
						var markers = response.data.data;
						var windows = [];
						for (let i = 0; i < markers.length; i++) {
							_this.windows.push({
								position: markers[i].coordinate ? [markers[i].coordinate[0], markers[i].coordinate[1] + 2.5] : markers[i].coordinate,
								content:
									'<div class="prompt">' +
									markers[i].name +
									'，crc人数' +
									markers[i].countCRCUser +
									'</div>',
								visible: false
							});
							markers[i].events = {
								click: () => {
									//                console.log(_this.windows);
									_this.windows.forEach(e => {
										e.visible = false;
									});

									_this.window = _this.windows[i];
									//                console.log(i);
									_this.$nextTick(() => {
										_this.window.visible = true;
									});
								}
							};
						}
						_this.markers = markers;
					}
				});
		}
	}
};
